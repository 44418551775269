export enum sensorMeasureType {
    IndoorTemperature = 29,
    OutdoorTemperature = 41,
    Temperature = 40,
    IndoorHygrometry = 31,
    CO2Rate = 32,
    NO2Rate = 39,
    Presence = 33,
    AirQuality = 37,
    Pression = 36,
    Brightness = 35,
    State = 34,
    Radon = 38,
    COV = 42
}

export enum DeviceStatusEnum {
    Inactive = 0,
    Active = 1,
    Deposed = 2
}
