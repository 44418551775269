import { Label } from '@app/core/models/common/label.model';
import { PerimeterLevel } from '@app/features/objectives/models/objective.enum';
import { PredicateDto } from '@app/features/perimeter/common/property.grid-configuration.class';
import { DeviceStatusEnum } from '@app/shared/constants/sensor-measure-types.enum';

export class PredicateDeviceDto extends PredicateDto {
    libelle: string;
    IDFournisseur: string;
    localisation: string;
    typeMesureId: number;
    dernierMessage: string;
    status: boolean;
    lastMessageDate: Date;
    deviceIds: number[];
}

export class DeviceResult {
    data: DeviceModel[];
    total: number;
}

export class DeviceModel {
    public adress: string;
    public city: string;
    public deviceBrand: string;
    public deviceId: number;
    public deviceLabel: string;
    public deviceLocalisations: DeviceLocalisationModel[];
    public gpsLatitude?: number;
    public gpsLongitude?: number;
    public installationDate: Date;
    public idActive: DeviceStatusEnum;
    public modelOfTheDevice: string;
    public postalCode: string;
    public removalDate: Date;
    public sensors: SensorModel[];
    public serialNumber: number;
    public supplierId: string;
    public deviceIdSitiers: number;
    public deviceInterfaceId: number;
    public lastMeasure: Date;
    public comments: string;
    public inactivityDelay?: number;
}

export class DeviceDescription {
    idActive: DeviceStatusEnum;
    label: string;
    supplierId: string;
    serialNumber: number;
    brand: string;
    model: string;
    installationDate: Date;
    removalDate: Date;
    comments: string;
    inactivityDelay?: number;
}

export class DeviceLocalisation {
    gpsLatitude?: number;
    gpsLongitude?: number;
    geographicLevel: number;
    idEntite: number;
}

export class DeviceLocalisationModel {
    deviceId: number;
    establishmentId: number;
    establishment: string;
    buildingId: number;
    building: string;
    zoneId: number;
    zone: string;
    startDate: Date;
    endDate: Date;
    address: string;
    postalCode: string;
    townId: number;
    townLabel: string;
    countyId: number;
    countyLabel: string;
    regionId: number;
    regionLabel: string;
    nationId: number;
    nationLabel: string;
}

export class SensorModel {
    public sensorId: number;
    public deviceId: number;
    public deviceLabel: string;
    public sensorLabel: string;
    public supplierId: string;
    public sensorMeasureType: SensorMeasureTypeModel;
    public unit: Label;
    public lastMeasureDate: Date;
    public valid: boolean;
    public update: boolean;
    public sensorIdSITiers: number;
    public sensorInterfaceId: number;
    public selected?: boolean;
}
export class SensorMeasureModel {
    sensor: SensorModel;
    measures: MeasureModel[];
}
export class PerimterLevelModel {
    id: number;
    label: string;
}

export class DeviceFilter {
    deviceIds?: number[];
    deviceLabel?: string;
    deviceSupplierId?: string;
    deviceLocalisation?: string;
    deviceActive?: boolean;
    lastReadingDateMax?: Date;
    lastReadingDateMin?: Date;
    perimeterLevel?: PerimeterLevel;
    perimeterItemId?: number[];
    globalPerimeterLevel?: PerimeterLevel;
    constructor(init?: Partial<DeviceFilter>) {
        Object.assign(this, init);
    }
}

export class SensorFilter {
    sensorIds?: number[];
    sensorLabel?: string;
    sensorExactSupplierId?: string;
    sensorSupplierId?: string;
    sensorMeasureTypeId?: number[];
    sensorIdsSITiers?: number[];
    sensorInterfaceId?: number;
    constructor(init?: Partial<SensorFilter>) {
        Object.assign(this, init);
    }
}

export class SensorMeasureTypeModel {
    measureType: string;
    measureTypeId: number;
    picto: string;
    mainUnit: Label;
    secondaryUnits: Label[];
    color: string;
    constructor() {
        this.measureType = '';
        this.measureTypeId = null;
        this.picto = '';
        this.color = '';
    }
}

export class MeasureModel {
    sensorId: number;
    dateTime: Date;
    measure: number;
}

/**
 * Modèle représentant les information d'un marqueur d'une localisation GPS
 */
export interface Marker {
    lat: number;
    lng: number;
    label?: string;
    draggable: boolean;
}
